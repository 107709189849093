@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
  --wac-body-font-family: 'Roboto', sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
    
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: 'Roboto', Arial, sans-serif;
}

@keyframes bounce {
  0% {
    transform: translateY(0); /* Start at normal position */
  }
  30% {
    transform: translateY(50px); /* Jump up slightly */
  }
  50% {
    transform: translateY(10px); /* Return to normal position */
  }
  70% {
    transform: translateY(10px); /* Small jump */
  }
  100% {
    transform: translateY(10); /* End at normal position */
  }
}







